import React, { useState } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import Airtable from "airtable";
import { NFTStorage, File } from 'nft.storage';
import './ArtistAndCrewPage.css';

const base = new Airtable({ apiKey: process.env.REACT_APP_AIRTABLE_API_KEY }).base('appWVYlGnpAqayTz1');
const nftStorageClient = new NFTStorage({ token: process.env.REACT_APP_NFT_STORAGE_API_KEY });

const ArtistAndCrewPage = () => {
  const { register, control, handleSubmit, reset } = useForm({
    defaultValues: {
      artistName: "",
      image: null,
      crewMembers: [{ name: "", email: "", cryptoWallet: "", jobTitle: "", startDate: "", endDate: "" }]
    }
  });
  const { fields, append, remove } = useFieldArray({ control, name: "crewMembers" });

  const onSubmit = async (data) => {
    let imageUrl = "";
    if (data.image && data.image.length > 0) {
      const imageFile = new File([data.image[0]], data.image[0].name, { type: data.image[0].type });
      const metadata = await nftStorageClient.storeBlob(imageFile);
      imageUrl = `https://ipfs.io/ipfs/${metadata}`;
    }

    try {
      await Promise.all(data.crewMembers.map(async (member) => {
        await base('Table 1').create([
          {
            fields: {
              "Artist Name": data.artistName, // Make sure this exact field name exists in Airtable
              "Full Name": member.name,
              "Email Address": member.email,
              "Crypto Wallet Address": member.cryptoWallet,
              "Job Title": member.jobTitle,
              "Start Date": member.startDate,
              "End Date": member.endDate,
              "Image": imageUrl
            }
          }
        ]);
        
      }));
      alert('Data submitted successfully!');
      reset();
    } catch (error) {
      console.error("Error: ", error);
      alert('Failed to submit data.');
    }
  };

  return (
    <div className="container">
      <div className="left-column">
        <h1>BUCL Dashboard</h1>
        <h2>Please add the required information about your crew members. Accreditation will be approved by our team.</h2>
      </div>
      <div className="right-column">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-container">
            <label htmlFor="artistName">Artist Name</label>
            <input id="artistName" {...register("artistName")} placeholder="Artist name" required />

            <label htmlFor="image">Upload Image</label>
            <Controller
              name="image"
              control={control}
              defaultValue={[]}
              render={({ field: { onChange, onBlur, ref } }) => (
                <input
                  type="file"
                  onChange={(e) => onChange(e.target.files)}
                  onBlur={onBlur}
                  ref={ref}
                />
              )}
            />
          </div>
          {fields.map((item, index) => (
            <div key={item.id} className="form-container">
              <h3>Crew Member {index + 1}</h3>
              <label htmlFor={`name-${index}`}>Full Name</label>
              <input id={`name-${index}`} {...register(`crewMembers.${index}.name`)} required />
              <label htmlFor={`email-${index}`}>Email Address</label>
              <input id={`email-${index}`} {...register(`crewMembers.${index}.email`)} type="email" required />
              <label htmlFor={`cryptoWallet-${index}`}>Crypto Wallet Address</label>
              <input id={`cryptoWallet-${index}`} {...register(`crewMembers.${index}.cryptoWallet`)} required />
              <label htmlFor={`jobTitle-${index}`}>Job Title</label>
              <input id={`jobTitle-${index}`} {...register(`crewMembers.${index}.jobTitle`)} required />
              <label htmlFor={`startDate-${index}`}>Start Date</label>
              <input id={`startDate-${index}`} {...register(`crewMembers.${index}.startDate`)} type="date" required />
              <label htmlFor={`endDate-${index}`}>End Date</label>
              <input id={`endDate-${index}`} {...register(`crewMembers.${index}.endDate`)} type="date" required />
              <button type="button" onClick={() => remove(index)}>Remove Crew Member</button>
            </div>
          ))}
          <section>
            <button type="button" onClick={() => append({ name: "", email: "", cryptoWallet: "", jobTitle: "", startDate: "", endDate: "" })}>Add Crew Member</button>
            <button type="submit">Submit All</button>
          </section>
        </form>
      </div>
    </div>
  );
};

export default ArtistAndCrewPage;
