import React from 'react';
import { useParams } from 'react-router-dom';

const MemberQRPage = () => {
  let { walletAddress } = useParams();

  return (
    <div>
      <h1>Member Page</h1>
      <p>This page will display information for the member with wallet address: {walletAddress}</p>
      {/* Additional content can be added here such as member details, NFTs owned, etc. */}
    </div>
  );
}

export default MemberQRPage;