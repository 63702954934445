import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ArtistAndCrewPage from "./components/ArtistAndCrewPage";
import ApproveEntriesPage from "./components/ApproveEntriesPage"; // Import the new component
import MemberQRPage from './components/MemberQRPage'; // This will be the component for the member pages



function App() {
  return (
    <Router>
      <div style={{ fontFamily: 'Lato', backgroundColor: '#000', color: '#fff', minHeight: '100vh' }}>
        <Routes>
          <Route path="/" element={<ArtistAndCrewPage />} />
          <Route path="/approve" element={<ApproveEntriesPage />} /> {/* New route for approval */}
          <Route path="/member-qr-page/:walletAddress" component={MemberQRPage} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
