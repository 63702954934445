import React, { useState, useEffect } from 'react';
import { NFTStorage, File } from 'nft.storage';
import { emailService } from './emailService';
import Airtable from 'airtable';
import './ArtistAndCrewPage.css';
import { BaseSepoliaTestnet } from "@thirdweb-dev/chains";
import { ThirdwebProvider, ConnectWallet, metamaskWallet, coinbaseWallet, walletConnect, trustWallet, useAddress, useSigner } from '@thirdweb-dev/react';
import { ethers } from 'ethers';
import contractABI from '../ABI.json';  // Update this path to your actual ABI JSON file location

// This should be the deployed address of your NFT contract on the blockchain
const contractAddress = '0x7d0892364377Db02D251329C1E2120c20aE78dA0';

// Configure NFT.Storage
const nftStorageClient = new NFTStorage({ token: process.env.REACT_APP_NFT_STORAGE_API_KEY });

// Configure Airtable
const base = new Airtable({ apiKey: process.env.REACT_APP_AIRTABLE_API_KEY }).base('appWVYlGnpAqayTz1');

const generateQRCode = async (openseaLink) => {
  try {
    const qrCodeURL = `https://api.qrserver.com/v1/create-qr-code/?size=300x300&data=${encodeURIComponent(openseaLink)}`;
    return qrCodeURL;
  } catch (error) {
    console.error("Error generating QR code:", error);
    throw error;
  }
};

const ApproveEntriesPage = () => {
  const [entries, setEntries] = useState({});
  const address = useAddress();
  const signer = useSigner();

  useEffect(() => {
    fetchEntries();
  }, []);

  const fetchEntries = async () => {
    const records = await base('Table 1').select({
      filterByFormula: "NOT({Approved} = 'Yes')"
    }).all();
    const groupedEntries = records.reduce((acc, record) => {
      const artist = record.fields['Artist Name'];
      if (!acc[artist]) {
        acc[artist] = [];
      }
      acc[artist].push({ id: record.id, ...record.fields });
      return acc;
    }, {});
    setEntries(groupedEntries);
  };

  const mintNFT = async (recipientAddress, metadataUrl) => {
    if (!signer) {
      alert("Please connect your wallet.");
      return;
    }

    const contract = new ethers.Contract(contractAddress, contractABI, signer);

    try {
      const tx = await contract.safeMint(recipientAddress, metadataUrl);
      const receipt = await tx.wait();
      const tokenId = receipt.events[0].args.tokenId.toString();
      console.log("NFT minted successfully with token ID:", tokenId);
      return tokenId;
    } catch (error) {
      console.error("Minting failed:", error);
      alert("Minting failed. See console for details.");
      throw error;
    }
  };

  const handleEntryUpdate = async (id, status) => {
    if (status === 'Yes') {
      const record = await base('Table 1').find(id);
      const metadata = createMetadata(record.fields);
      const metadataUrl = await uploadMetadataToIPFS(metadata);
      const tokenId = await mintNFT(record.fields['Crypto Wallet Address'], metadataUrl);

      const openseaLink = `https://testnets.opensea.io/assets/base-sepolia/${contractAddress}/${tokenId}`;
      const qrCodeURL = await generateQRCode(openseaLink);

      await emailService(record.fields['Email Address'], qrCodeURL);

      // Store QR code URL in Airtable
      await base('Table 1').update([{ id, fields: { 'qrcode': qrCodeURL } }]);
    }

    // Update the approval status in Airtable
    await base('Table 1').update([{ id, fields: { 'Approved': status } }]);
    fetchEntries();
  };

  const createMetadata = (fields) => ({
    name: fields['Artist Name'],
    description: "", // Leave empty as specified
    image: fields['Image'],
    attributes: [
      { trait_type: "Full Name", value: fields['Full Name'] }
    ]
  });

  const uploadMetadataToIPFS = async (metadata) => {
    const blob = new Blob([JSON.stringify(metadata)], { type: 'application/json' });
    const file = new File([blob], "metadata.json");
    const cid = await nftStorageClient.storeBlob(file);
    return `https://ipfs.io/ipfs/${cid}`;
  };

  return (
    <div className="container">
      <div className="left-column">
        <h1>Approve Crew Members</h1>
      </div>
      <div className="right-column">
        <ConnectWallet style={{ backgroundColor: '#b8dd97', color: '#000000', fontWeight: '700' }} />
        {Object.keys(entries).map(artist => (
          <div key={artist}>
            <h2>Artist: {artist}</h2>
            {entries[artist].map(entry => (
              <div key={entry.id} className="form-container">
                <h3>{entry['Full Name']}</h3>
                <p>Email: {entry['Email Address']}</p>
                <p>Crypto Wallet: {entry['Crypto Wallet Address']}</p>
                <p>Job Title: {entry['Job Title']}</p>
                <p>Start Date: {entry['Start Date']}</p>
                <p>End Date: {entry['End Date']}</p>
                <p>Image: <a href={entry['Image']} target="_blank" rel="noopener noreferrer">View Image</a></p>
                <button onClick={() => handleEntryUpdate(entry.id, 'Yes')}>Approve</button>
                <button onClick={() => handleEntryUpdate(entry.id, 'No')}>Reject</button>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

const WrappedApproveEntriesPage = () => (
  <ThirdwebProvider activeChain={BaseSepoliaTestnet} clientId="9d0cd6937119b9ffcda97be6bd8a180d" supportedWallets={[metamaskWallet(), coinbaseWallet(), walletConnect(), trustWallet()]}>
    <ApproveEntriesPage />
  </ThirdwebProvider>
);

export default WrappedApproveEntriesPage;
