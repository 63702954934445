import emailjs from 'emailjs-com';

const emailService = async (recipientEmail, qrCodeURL) => {
  try {
    const serviceID = 'service_xdehuuv';  // Replace with your EmailJS service ID
    const templateID = 'template_l1ecv5o'; // Replace with your EmailJS template ID
    const userID = 'xQSJOa_JTbn6pMZms';  // Use your public API key as the userID


    const templateParams = {
      to_email: recipientEmail,
      qr_code_image: qrCodeURL
    };

    console.log(qrCodeURL)
    const response = await emailjs.send(serviceID, templateID, templateParams, userID);
    console.log('Email sent successfully:', response.status, response.text);
  } catch (error) {
    console.error('Failed to send email:', error);
    throw error;
  }
};

export { emailService };
